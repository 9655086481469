import { createStorken } from 'storken'
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { getCurrentUser } from '../services/UserActions'
// eslint-disable-next-line import/named,import/no-cycle
import {
  getCategories,
  getOrders,
  getProducts,
  currentOrder,
  getDates,
  getcustomers,
  getallorders,
  getMonthlyOrders, getGifts, getAllGifts, getOffers,
} from '../services/AdminActions'
// eslint-disable-next-line import/no-cycle
import { auth } from '../services/CustomerActions'

const { sessionStorage, localStorage, window } = global

export const [useLive, getLive, setLive] = createStorken({
  getters: {
    user: () => getCurrentUser(),
    categories: () => getCategories(),
    products: () => getProducts(),
    orders: () => getOrders(),
    currentOrder: () => currentOrder(),
    isAuth:() => auth(),
    dates:() => getDates(getSync('thisMonth')),
    customers: () => getcustomers(),
    allorders: () => getallorders(),
    monthlyOrders: () => getMonthlyOrders(getSync('thisMonth')),
    gifts: () => getGifts(),
    allgifts: () => getAllGifts(),
    offers:() => getOffers()
  },
  initialValues: {
    isAuth:false,
    level:0,
    user:{},
    categories:[],
    products:[],
    orders:[],
    currentOrder:{},
    dates:[],
    customers:[],
    allorders:[],
    monthlyOrders:[],
    gifts:[],
    offers:[]
  }
})


export const [useSync, getSync, setSync, SyncStorken] = createStorken({
  options: {
    broadcast: window
  },
  initialValues: {
    basket      : [],
    order       : {},
    onleft      : false,
    openbasket  : false,
    placement   : 'bottom',
    cost        : 0,
    uploadStatus:false,
    package:false,
    load:false,
    isPack:false,
    thisMonth:0,
    active:{ name:'tumurunler' },
    firstStart: true,
    step:0,
    verifyCode:0,
    numberIsVerif:false,
    dis:false,
    disprod:0,
    cupons:[],
    cupon:{ nth: 0 },
    tereyagAlert:false,
    adminLoadAnim:false,
    adminLoadMsg:'',
    envanterModal:false,
    envanterArr:[],
    rejectedCargo:[],
    showLocationSelect:false
  }
})
export const [useSession, setSession, getSession, SessionStorken] = createStorken({

  options: {
    storage   : sessionStorage,
    broadcast : window
  },
  initialValues:{
    editMode:false,
    editingOrder:{},
    cuponCode:{},
    deliveryCity:''

  }
})
export const [useStorage, getStorage, setStorage] = createStorken({
  options: {
    storage:    localStorage
  },
  initialValues:{
    sessionUser:{},
    token:'',
    tokenCustomer:{},
    customer:{},
    baskets:[],
    costs :'',
    verifyUser:{
      phone:'',
      verify:false
    },
    gift:{
      visit:0,
      added:{},
      date:''
    }
  }
})
